import axios from 'axios'
import { Currency, NATIVE_CURRENCY, Trade } from 'sdk'
import { ChainId, odosBaseUrl, polygonReferralCode } from 'sdk/constants'
import { Box } from 'shared'
import Big from 'big.js'
import { CurrencyDirection } from 'enums/common'
import { useContext, useEffect, useState } from 'react'
import styled, { ThemeContext } from 'styled-components'
import { useUserSlippageTolerance } from '../../state/user/hooks'
import { TYPE } from '../../theme'
import { AutoColumn } from '../Column'
import { RowBetween, RowFixed } from '../Row'
import SlippageTabs from 'components/TransactionSettings'
import { useDerivedSwapInfo, useSelectedCurrencies } from 'state/swap/hooks'
import { formatByDecimals, formatNumber, formatToCurrency, formatToUSD } from 'utils/formatters'
import { calculatePercentageDifference } from 'utils/calculatePercentageDifference'
import { useChainId } from 'hooks'
import { useCGListedTokenPricesByNetwork } from 'hooks/coingecko/useCGListedTokenPricesByNetwork'
import { useCGTokenPrices } from 'hooks/coingecko/useCGTokenPrices'
import { useODOSTokensPrices } from 'hooks/ODOS/useODOSTokensPrices'
import Skeleton from 'react-loading-skeleton'
import { ClickableText } from 'pages/Pool/styleds'
import QuestionHelper from 'components/QuestionHelper'
import { INITIAL_ALLOWED_SLIPPAGE } from '../../constants'
import { useToggleSettingsMenu } from 'state/application/hooks'
import { useWeb3React } from '@web3-react/core'
import { LoadingDotsIcon } from 'ui/LoadingDotsIcon'

const SlippageBox = styled.div`
  margin-top: 16px;
`

const percentageFormatter = new Intl.NumberFormat('en-US', { maximumFractionDigits: 2 })

function TradeSummary({
  currency,
  inputAmount,
  outputAmount,
}: {
  allowedSlippage: number
  currency: Currency | undefined
  inputAmount: string
  outputAmount: string
}) {
  const theme = useContext(ThemeContext)
  const chainId = useChainId()
  const { wrappedInputCurrency, wrappedOutputCurrency, inputCurrency, outputCurrency } = useSelectedCurrencies()
  const [gasPrice, setGasPrice] = useState<number>(0)
  const { account } = useWeb3React()
  const [gasLoading, setGasLoading] = useState<boolean>(false)
  const [inputCurrencyUSD, setInputCurrencyUSD] = useState<number>(0)
  const [price, setPrice] = useState<number>(0)
  const [pricePending, setPricePending] = useState<boolean>(false)
  const { fetchSelectedTokensPrices } = useODOSTokensPrices()
  const [allowedSlippage] = useUserSlippageTolerance()
  const toggleSettings = useToggleSettingsMenu()
  const pricesQuery: any = useCGListedTokenPricesByNetwork('V3')
  const { data: lif3PriceQuery } = useCGTokenPrices({ tokenSymbols: ['LIF3'] })

  const fetchGas = async () => {
    if (gasLoading || !inputAmount || !parseFloat(inputAmount)) {
      setGasPrice(0)
      return
    }

    const amount = inputAmount

    const from =
      inputCurrency === NATIVE_CURRENCY[chainId]
        ? '0x0000000000000000000000000000000000000000'
        : wrappedInputCurrency?.address

    const to =
      outputCurrency === NATIVE_CURRENCY[chainId]
        ? '0x0000000000000000000000000000000000000000'
        : wrappedOutputCurrency?.address

    try {
      const fromAmountInWei = formatByDecimals(Number(amount), inputCurrency?.decimals as number)
      const quoteRequestBody = {
        chainId,
        compact: true,
        inputTokens: [
          {
            amount: fromAmountInWei.toString(),
            tokenAddress: from,
          },
        ],
        outputTokens: [
          {
            proportion: 1,
            tokenAddress: to,
          },
        ],
        referralCode: chainId === ChainId.POLYGON ? polygonReferralCode : 0,
        slippageLimitPercent: allowedSlippage / 100,
        sourceBlacklist: [],
        sourceWhitelist: [],
        userAddr: account,
      }

      const headers = { 'Content-Type': 'application/json' }
      const quoteUrl = `${odosBaseUrl}/sor/quote/v2`
      const quoteResponse = await axios.post(quoteUrl, quoteRequestBody, {
        headers,
      })
      const { gasEstimateValue } = quoteResponse.data
      setGasPrice(Number(gasEstimateValue))
    } catch {
      return
    } finally {
      setGasLoading(false)
    }
  }

  useEffect(() => {
    fetchGas()
  }, [inputAmount])

  useEffect(() => {
    const load = async () => {
      try {
        setPricePending(true)
        const price = await fetchSelectedTokensPrices()

        setPrice(!price || isNaN(price) ? 0 : price)
        let res: string = ''
        if (inputCurrency?.symbol) {
          const tokenPriceBaseUrl = `${odosBaseUrl}/pricing/token`
          const address =
            inputCurrency === NATIVE_CURRENCY[chainId]
              ? '0x0000000000000000000000000000000000000000'
              : wrappedInputCurrency?.address
          const { data: priceUSDResponse } = await axios.get(`${tokenPriceBaseUrl}/${chainId}/${address}`)
          const { price: priceUSD } = priceUSDResponse

          res = priceUSD as string
        }

        if (res === '0' && inputCurrency?.address) {
          res = pricesQuery.data[inputCurrency.address.toLowerCase()].usd
        }

        setInputCurrencyUSD(Number(res))
      } catch (err: any) {
        console.log(err.message)
      } finally {
        setPricePending(false)
      }
    }

    load()
  }, [inputCurrency?.address, outputCurrency?.address])

  const inputPrice =
    currency?.symbol === 'LIF3'
      ? lif3PriceQuery?.data?.LIF3?.usd
      : pricesQuery?.data?.[wrappedInputCurrency?.address.toLowerCase() ?? '']?.usd

  const outputPrice =
    currency?.symbol === 'LIF3'
      ? lif3PriceQuery?.data?.LIF3?.usd
      : pricesQuery?.data?.[wrappedOutputCurrency?.address.toLowerCase() ?? '']?.usd

  const inputUsdAmount =
    inputPrice && inputAmount && Number(inputAmount) ? Big(Number(inputAmount)).mul(inputPrice) : null
  const outputUsdAmount =
    outputPrice && outputAmount && Number(outputAmount) ? Big(Number(outputAmount)).mul(outputPrice) : null

  const priceDiff =
    inputUsdAmount && outputUsdAmount
      ? calculatePercentageDifference(inputUsdAmount.toNumber(), outputUsdAmount.toNumber())
      : null

  return (
    <>
      <AutoColumn gap="4px">
        <RowBetween>
          {pricePending ? (
            <Skeleton baseColor="#090D1A" width={200} />
          ) : (
            <RowFixed>
              <TYPE.black fontSize={13} color={theme.secondaryText1}>
                1 {inputCurrency?.symbol} = {formatToCurrency(price)} {outputCurrency?.symbol} (~
                {formatToUSD(inputCurrencyUSD)})
              </TYPE.black>
            </RowFixed>
          )}
        </RowBetween>
        {allowedSlippage !== INITIAL_ALLOWED_SLIPPAGE && (
          <RowBetween align="center">
            <ClickableText fontWeight={400} fontSize={13} color={theme.secondaryText1} onClick={toggleSettings}>
              Slippage Tolerance
            </ClickableText>
            <Box display="flex" alignItems="center">
              <ClickableText fontWeight={400} fontSize={13} color={theme.primaryText1} onClick={toggleSettings}>
                {formatToCurrency(allowedSlippage / 100)}%
              </ClickableText>
              <QuestionHelper text="Your transaction will revert if the price changes unfavorably by more than this percentage." />
            </Box>
          </RowBetween>
        )}
        <RowBetween>
          <RowFixed>
            <TYPE.black fontSize={13} fontWeight={400} color={theme.secondaryText1}>
              Gas Cost
            </TYPE.black>
          </RowFixed>
          <RowFixed>
            <TYPE.black color={theme.primaryText1} fontSize={13}>
              {gasLoading ? <LoadingDotsIcon size={13} /> : formatNumber(gasPrice, 4) + ' USD'}
            </TYPE.black>
          </RowFixed>
        </RowBetween>
        {priceDiff && priceDiff > 0 && (
          <Box fontSize={13} alignItems="end" display="flex" flexDirection="column">
            <Box>Within {percentageFormatter.format(priceDiff)}% of current market price</Box>
            {priceDiff >= 3 && <Box color={theme.red1}>Warning: potential low liquidity trade</Box>}
          </Box>
        )}
      </AutoColumn>
    </>
  )
}

export interface AdvancedSwapDetailsProps {
  trade?: Trade | undefined
  currency: Currency | undefined
  inputAmount: string
  outputAmount: string
}

export function AdvancedSwapDetailsFixed({ trade, currency, ...rest }: AdvancedSwapDetailsProps) {
  const [allowedSlippage] = useUserSlippageTolerance()

  const { currencies } = useDerivedSwapInfo()

  return (
    <AutoColumn gap="0px">
      <TradeSummary
        inputAmount={rest.inputAmount}
        outputAmount={rest.outputAmount}
        currency={currency}
        allowedSlippage={allowedSlippage}
      />
      <SlippageBox>
        <SlippageTabs
          currencies={{ tokenA: currencies[CurrencyDirection.INPUT], tokenB: currencies[CurrencyDirection.OUTPUT] }}
        />
      </SlippageBox>
    </AutoColumn>
  )
}
