import { Currency, TradeType } from '@uniswap/sdk-core'
import { RowFixed } from 'components/Row'
import { MouseoverTooltip } from 'components/Tooltip'
import SwapRoute from './SwapRoute'
import { InterfaceTrade } from 'hooks/v3/types'
import { Text } from 'rebass'
import { formatToCurrency } from 'utils/formatters'

export default function GasEstimateTooltip({
  trade,
  loading,
}: {
  trade: InterfaceTrade<Currency, Currency, TradeType> // dollar amount in active chain's stablecoin
  loading: boolean
  disabled?: boolean
}) {
  const formattedGasPriceString = trade?.gasUseEstimateUSD
    ? trade.gasUseEstimateUSD.toFixed(2) === '0.00'
      ? '<$0.01'
      : '$' + formatToCurrency(Number(trade.gasUseEstimateUSD.toFixed(2)))
    : undefined

  return (
    <MouseoverTooltip text={<SwapRoute trade={trade} syncing={loading} />} placement="bottom">
      <RowFixed>
        <Text>{formattedGasPriceString}</Text>
      </RowFixed>
    </MouseoverTooltip>
  )
}
