import { StaticJsonRpcProvider } from '@ethersproject/providers'
import { InjectedConnector } from '@web3-react/injected-connector'
import { NetworkConnector } from './NetworkConnector'

import { WalletConnectConnector } from '@web3-react/walletconnect-connector'
import { ChainId } from 'sdk'

export const RPC = {
  [ChainId.FANTOM]: `https://frosty-alpha-bird.fantom.quiknode.pro/9d1e16831f6ae13a7ed6f278eb31cd3d860d849a`,
  [ChainId.TOMBCHAINTESTNET]: 'https://testtomb.tombchain.com/',
  [ChainId.TOMB]: 'https://rpc.tombchain.com/',
  [ChainId.BSC]: 'https://young-serene-road.bsc.quiknode.pro/8272388efac458121c2ef9ea2ab8be96b5192abd/',
  [ChainId.POLYGON]: `https://lively-solemn-friday.matic.quiknode.pro/83b783c53d28666903598b50f295c4b904fb061f`,
  [ChainId.AMOY_TESTNET]: `https://floral-ancient-dust.matic-amoy.quiknode.pro/3d6e050f3b26d9c5a5bc47376da08158800df4fd/`,
  [ChainId.ETHEREUM]: 'https://wiser-intensive-dinghy.quiknode.pro/9025ea401faa350231e107e85e266cdb576ddf48',
  // [ChainId.OPTIMISM]: `${ankrBaseUrl}/optimism/${ankrKey}`,
  // [ChainId.ARBITRUM]: `${ankrBaseUrl}/arbitrum/${ankrKey}`,
  [ChainId.BASE]: 'https://orbital-billowing-seed.base-mainnet.quiknode.pro/a99324f5596ba26ceed96317c6f398dafd2a0f64',
  [ChainId.LIF3CHAIN_TESTNET]: 'https://testnet-evm.lif3.com/',
}

const connections = {
  [ChainId.FANTOM]: {
    url: RPC[ChainId.FANTOM],
  },
  [ChainId.TOMBCHAINTESTNET]: {
    url: RPC[ChainId.TOMBCHAINTESTNET],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.TOMB]: {
    url: RPC[ChainId.TOMB],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.BSC]: {
    url: RPC[ChainId.BSC],
    // headers: {
    //   'Cache-Control': 'max-age=86000, public',
    // },
  },
  [ChainId.POLYGON]: {
    url: RPC[ChainId.POLYGON],
    // headers: {
    //   'Cache-Control': 'max-age=86000, public',
    // },
  },
  [ChainId.AMOY_TESTNET]: {
    url: RPC[ChainId.AMOY_TESTNET],
    // headers: {
    //   'Cache-Control': 'max-age=86000, public',
    // },
  },
  [ChainId.ETHEREUM]: {
    url: RPC[ChainId.ETHEREUM],
    // headers: {
    //   'Cache-Control': 'max-age=86000, public',
    // },
  },
  [ChainId.OPTIMISM]: {
    url: RPC[ChainId.OPTIMISM],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.ARBITRUM]: {
    url: RPC[ChainId.ARBITRUM],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
  [ChainId.BASE]: {
    url: RPC[ChainId.BASE],
    // headers: {
    //   'Cache-Control': 'max-age=86000, public',
    // },
  },
  [ChainId.LIF3CHAIN_TESTNET]: {
    url: RPC[ChainId.LIF3CHAIN_TESTNET],
    headers: {
      'Cache-Control': 'max-age=86000, public',
    },
  },
}

export const PROVIDERS_BY_CHAIN = {
  [ChainId.TOMBCHAINTESTNET]: new StaticJsonRpcProvider(
    connections[ChainId.TOMBCHAINTESTNET],
    ChainId.TOMBCHAINTESTNET
  ),
  [ChainId.TOMB]: new StaticJsonRpcProvider(connections[ChainId.TOMB], ChainId.TOMB),
  [ChainId.FANTOM]: new StaticJsonRpcProvider(connections[ChainId.FANTOM], ChainId.FANTOM),
  [ChainId.BSC]: new StaticJsonRpcProvider(connections[ChainId.BSC], ChainId.BSC),
  [ChainId.POLYGON]: new StaticJsonRpcProvider(connections[ChainId.POLYGON], ChainId.POLYGON),
  [ChainId.AMOY_TESTNET]: new StaticJsonRpcProvider(connections[ChainId.AMOY_TESTNET], ChainId.AMOY_TESTNET),
  [ChainId.ETHEREUM]: new StaticJsonRpcProvider(connections[ChainId.ETHEREUM], ChainId.ETHEREUM),
  [ChainId.OPTIMISM]: new StaticJsonRpcProvider(connections[ChainId.OPTIMISM], ChainId.OPTIMISM),
  [ChainId.ARBITRUM]: new StaticJsonRpcProvider(connections[ChainId.ARBITRUM], ChainId.ARBITRUM),
  [ChainId.BASE]: new StaticJsonRpcProvider(connections[ChainId.BASE], ChainId.BASE),
  [ChainId.LIF3CHAIN_TESTNET]: new StaticJsonRpcProvider(
    connections[ChainId.LIF3CHAIN_TESTNET],
    ChainId.LIF3CHAIN_TESTNET
  ),
}

export const getNetwork = (chainId: number) => {
  PROVIDERS_BY_CHAIN[chainId]._pollingInterval = 250

  return new NetworkConnector({
    defaultChainId: chainId,
    urls: RPC,
  })
}

export const SUPPORTED_NETWORKS_IDS = [
  ChainId.FANTOM,
  ChainId.BSC,
  ChainId.POLYGON,
  ChainId.AMOY_TESTNET,
  ChainId.ETHEREUM,
  // temporarily unavailable chains
  // ChainId.OPTIMISM,
  // ChainId.ARBITRUM,
  // ChainId.BASE,
  // ChainId.LIF3CHAIN_TESTNET,
]

export const injected = new InjectedConnector({
  supportedChainIds: SUPPORTED_NETWORKS_IDS,
})

// mainnet only
export const walletconnect = new WalletConnectConnector({
  rpc: RPC,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
  supportedChainIds: SUPPORTED_NETWORKS_IDS,
})
